// Create a custom hook to manage the client instance
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { APIClient } from './clientAPI'
import { useDispatch } from 'react-redux';

const useApiClient = () => {
  const dispatch = useDispatch();
  let api = useSelector(x => x?.auth?.user?.apiURL);

  console.log(process.env.REACT_APP_STAGE);
  if (process.env.REACT_APP_STAGE === 'prod-internal') {
    api=process.env.REACT_APP_SERVER_URL;
  }

  const token = useSelector(x => x?.auth.user?.accessToken);

  // Ensure the client is created only once using useEffect with an empty dependency array
  const client = useMemo(() => {
    if (api) {
      return new APIClient(api, dispatch);
    }
  }, [api, token]);

  return client;
};

export default useApiClient;
