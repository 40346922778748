import axios from "axios";
import { Notification } from "../components/shared";
import { notification } from "antd";
import dayjs from "dayjs";
import jwtDecode from "jwt-decode";
import { authActions } from "../state/features/authReducer";
export class APIClient {
  logOut = () => {
    this.dispatch(authActions.logout());
  };

  checkSessionExpiry = (token) => {
    try {
      let res = false;
      if (token) {
        const decodedPayload = jwtDecode(token);
        if (decodedPayload?.exp) {
          const expirationTime = decodedPayload.exp;
          const currentTime = Math.floor(Date.now() / 1000);
          if (currentTime > expirationTime) {
            res = true;
          } else {
            //console.log("not expired");
          }
        } else {
          res = true;
        }
      } else {
        console.log("no token");
        res = true;
      }
      return res;
    } catch (ex) {
      console.error("Exception in checkSessionExpiry - ", ex);
      return true;
    }
  };

  baseUrl = `${process.env.REACT_APP_AUTH_URL}`;

  refreshAccessToken = async (refreshToken) => {
    const form = new FormData();
    form.append("token", refreshToken);
    const body = new URLSearchParams(form);
    try {
      const response = await fetch(this.baseUrl + "/refresh-token", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: body,
      });
      const data = await response.json();
      //console.log(data);
      if (data?.$metadata?.httpStatusCode === 200) {
        if (!data?.AuthenticationResult?.AccessToken) {
          throw Error("No Access Token - ", data.message);
        } else {
          let userObj = {
            idToken: data?.AuthenticationResult?.IdToken,
            accessToken: data?.AuthenticationResult?.AccessToken,
            // "expiresIn": data?.AuthenticationResult?.ExpiresIn,
            apiURL: data?.apiURL,
            socketURL: data?.socketURL,
            refreshToken: this.refreshTokenInitial,
          };
          localStorage.setItem("is-refresh-token-requested", "false");
          return userObj;
        }
      } else {
        throw Error("Failed to fetch New Token!");
      }
    } catch (e) {
      throw e;
    }
  };

  async refreshToken() {
    try {
      const refreshToken = this.refreshTokenInitial;
      if (!refreshToken) {
        this.logOut();
        this.cancelTokenSource.cancel(
          "Request canceled - Token Expired No refresh token available."
        );
      }
      // Perform the token refresh operation
      const newAccessTokenObj = await this.refreshAccessToken(refreshToken);
      const newAccessToken = newAccessTokenObj?.accessToken;
      this.dispatch(authActions.refreshToken({ userInfo: newAccessTokenObj }));
      return newAccessToken;
    } catch (error) {
      this.logOut();
      this.cancelTokenSource.cancel("Request canceled - ", error);
    }
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  getUserInfo = () => {
    const userObj = localStorage.getItem("user") ?? "";
    const userJson = JSON.parse(userObj);
    const accessToken = userJson?.accessToken;
    const refreshToken = userJson?.refreshToken;
    const expiresIn = userJson?.expiresIn;
    return { accessToken, refreshToken, expiresIn };
  };

  constructor(url, dispatch) {
    this.dispatch = dispatch;

    const userInfo = this.getUserInfo();

    this.refreshTokenInitial = userInfo?.refreshToken;

    // Create a CancelToken source
    this.cancelTokenSource = axios.CancelToken.source();

    this.instance = axios.create({
      baseURL: url,
      headers: {
        Authorization: `Bearer ${userInfo?.accessToken}`,
      },
      cancelToken: this.cancelTokenSource.token,
    });

    this.instance.interceptors.request.use(
      async (config) => {
        if (config?.headers?.Authorization) {
          try {
            const userInfo = this.getUserInfo(); // can fail if no user is set, prompting logout
            const token = userInfo?.accessToken;
            // Check if the token is expired
            config.headers.Authorization = `Bearer ${token}`; //make sure it's using the latest
            const isExpired = this.checkSessionExpiry(token);
            if (isExpired) {
              console.log("expired and attempting to refresh");
              if (!userInfo?.expiresIn) {
                // Refresh the token (if not already in progress)
                if (
                  localStorage.getItem("is-refresh-token-requested") === "true"
                ) {
                  while (true) {
                    if (
                      localStorage.getItem("is-refresh-token-requested") ===
                      "false"
                    ) {
                      break;
                    }
                    await this.sleep(200);
                  }
                  const userInfo = this.getUserInfo();
                  const token = userInfo?.accessToken;
                  config.headers.Authorization = `Bearer ${token}`;
                  return config;
                } else {
                  localStorage.setItem("is-refresh-token-requested", "true");
                  const newAccessToken = await this.refreshToken();
                  config.headers.Authorization = `Bearer ${newAccessToken}`;
                  localStorage.setItem("is-refresh-token-requested", "false");
                  return config;
                }
              } else {
                this.logOut();
                this.cancelTokenSource.cancel(
                  "Request canceled - Token Expired No Refresh Token!"
                );
                //throw err
              }
            }
          } catch (error) {
            console.log("something failed", error);
            this.logOut();
            this.cancelTokenSource.cancel("Request canceled - ", error);
            //throw err
          }
        } else {
          this.logOut();
          this.cancelTokenSource.cancel(
            "Request canceled - Authorization not found!"
          );
          //throw errr
        }
        return config;
      },
      (error) => {
        this.logOut();
        this.cancelTokenSource.cancel("Error in interceptor - ", error);
      }
    );

    // this.instance.interceptors.response.use(function (response){
    //     return response
    // }, async function (error) {
    //     console.log(error.request);
    //     if (401 === error.request.status) {
    //         // handle error: inform user, go to login, etc
    //         console.log('401');
    //         await this.refreshToken();
    //     } else {
    //         return Promise.reject(error);
    //     }
    // })
  }

  handlePagination = (url, pageNum) => {
    if (pageNum > 1) {
      return `${url}?page_num=${pageNum}`;
    }

    return url;
  };

  catchError = (title, functionName, error) => {
    console.log(`Exception in  ${functionName}:`, error);
    if (!axios.isCancel(error)) {
      Notification.FailedNotification(title, error?.message);
      return {};
    }
  };

  async getRules(cellId, productId) {
    try {
      const cell_id = Number(cellId);
      const product_id = Number(productId);
      const response = await this.instance(
        `/rule-configs/rules/cell/${cell_id}/product/${product_id}`
      );
      return response?.data ?? {};
    } catch (error) {
      this.catchError("Rules Configuration", "getRules", error);
    }
  }

  async getServiceUrl(jobDescription, facilityDescription, cellId) {
    try {
      const job = String(jobDescription).toLowerCase();
      const facility = String(facilityDescription).toLowerCase();
      const cell = Number(cellId);
      const queryParams = { job, facility, cell };
      const response = await this.instance(`/service-url`, {
        params: queryParams,
      });
      return response?.data ?? {};
    } catch (error) {
      this.catchError("Service URL", "getServiceUrl", error);
    }
  }

  async getLoadRules(serviceUrl) {
    try {
      const serviceAddress = String(serviceUrl).toLowerCase();
      const response = await this.instance(
        `/rules-controller/loadrules/instance/${serviceAddress}`
      );
      return response?.data ?? {};
    } catch (error) {
      this.catchError("Rules Controller", "getLoadRules", error);
    }
  }

  async getImage(cellId, productId) {
    try {
      const response = await this.instance(
        `/product-images/cell/${cellId}/product/image/${productId}`
      );
      return response;
    } catch (error) {
      //his.catchError('Rules Configuration', 'getImage', error);
    }
  }

  async getAllFacilities() {
    try {
      const response = await this.instance.get(`/facilities/facilities`);
      return response?.data ?? {};
    } catch (error) {
      this.catchError("Facilities", "getFacilities", error);
    }
  }

  async getFacilityCells(facility_id) {
    try {
      const facilityId = Number(facility_id);
      const response = await this.instance.get(
        `/cells/cells/facility/${facilityId}`
      );
      return response?.data ?? {};
    } catch (error) {
      this.catchError("Cells", "getCells", error);
    }
  }

  async getProductCellAssignedPart(part_id, cell_id, page_num, page_size) {
    try {
      const productId = Number(part_id);
      const cellId = Number(cell_id);
      const queryParams = { page_num, page_size };
      const response = await this.instance.get(
        `/products-assigned/assigned-parts/cell/${cellId}/products/${productId}`,
        { params: queryParams }
      );
      return response?.data ?? {};
    } catch (error) {
      this.catchError("Products", "getProductCellAssignedPart", error);
    }
  }

  async getRecordPartAttribute(
    facility_line_id,
    part_id,
    attribute_id,
    facility_line_assigned_parts_id
  ) {
    try {
      const cell_id = Number(facility_line_id);
      const queryParams = {
        part_id,
        attribute_id,
        facility_line_assigned_parts_id,
      };
      const response = await this.instance.get(
        `/part-attributes/part-attributes/cellId/${cell_id}`,
        { params: queryParams }
      );
      return response?.data ?? {};
    } catch (error) {
      this.catchError("Products", "getRecordPartAttribute", error);
    }
  }

  async getCellParts(cell_id, only_active, page_num) {
    try {
      const cellId = Number(cell_id);
      const queryParams = { only_active, page_num };
      const response = await this.instance.get(
        `/products-assigned/assigned-parts/cell/${cellId}/products`,
        { params: queryParams }
      );
      return response?.data ?? {};
    } catch (error) {
      this.catchError("Parts", "getCellAssignedParts", error);
    }
  }

  async getFacilityProducts(facility_id) {
    try {
      const facilityId = Number(facility_id);
      const response = await this.instance.get(
        `/products/facilityId/${facilityId}`
      );
      return response?.data ?? {};
    } catch (error) {
      this.catchError("Parts", "getFacilityProducts", error);
    }
  }

  async getEquipment(cell_id) {
    try {
      const cellId = Number(cell_id);
      const response = await this.instance.get(`/equipment/cell/${cellId}`);
      return response?.data ?? {};
    } catch (error) {
      this.catchError("Equipment", "getEquipment", error);
    }
  }

  async getEquipmentTypes(cell_id) {
    try {
      const response = await this.instance.get(
        `/equipment/meta/equipment-types`
      );
      return response?.data ?? {};
    } catch (error) {
      this.catchError("Equipment", "getEquipmentTypes", error);
    }
  }

  async updateEquipment(id, editEquipmentRequestBody) {
    try {
      const url = `/equipment/equipmentid?id=${id}`;
      const response = await this.instance.put(url, editEquipmentRequestBody);
      return response?.status;
    } catch (error) {
      this.catchError("Equipment", "updateEquipment", error);
    }
  }

  async postRulesProfileImage(cellId, productId, imageRequestBody) {
    try {
      const formData = new FormData();
      formData.append("file", imageRequestBody);

      const response = await this.instance.post(
        `product-images/cell/${cellId}/product/image/${productId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            ...this.instance.headers,
          },
        }
      );
      Notification.SuccessNotification("Rules", "Image uploaded successfully");
      return response?.status;
    } catch (error) {
      this.catchError("Rules", "postRulesProfileImage", error);
    }
  }

  async createRule(cellId, productId, newRuleRequestBody) {
    try {
      const cell_id = Number(cellId);
      const product_id = Number(productId);
      const url = `/rule-configs/rules/cell/${cell_id}/product/${product_id}`;
      const response = await this.instance.post(url, newRuleRequestBody);
      return response?.status;
    } catch (error) {
      this.catchError("Rules Configuration", "createRule", error);
    }
  }

  async updateRule(cellId, ruleId, editRuleRequestBody) {
    try {
      const cell_id = Number(cellId);
      const rule_id = Number(ruleId);
      const url = `/rule-configs/rules/cell/${cell_id}/rule/${rule_id}`;
      const response = await this.instance.put(url, editRuleRequestBody);
      return response?.status;
    } catch (error) {
      this.catchError("Rules Configuration", "updateRule", error);
    }
  }

  async deleteRule(cellId, ruleId) {
    try {
      const cell_id = Number(cellId);
      const rule_id = Number(ruleId);
      const url = `/rule-configs/rules/cell/${cell_id}/rule/${rule_id}`;
      const response = await this.instance.delete(url);
      Notification.SuccessNotification(
        "Rules Configuration",
        "Deleted Successfully!"
      );
      return response?.status;
    } catch (error) {
      this.catchError("Rules Configuration", "deleteRule", error);
    }
  }

  async getStateActionMatrices(cellId, productId) {
      try {
          const cell_id = Number(cellId);
          const product_id = Number(productId);
          const response = await axios.get(`http://localhost:3002/api/v1/customer-instance/state_action_matrix/cell/${cell_id}/product/${product_id}`);
          return response?.data ?? {};
      } catch (error) {
          this.catchError('State Action Matrix Configuration', 'getStateActionMatrices', error);
      }
  }

  async getStateActionMatrix(cellId, matrixId) {
      try {
          const cell_id = Number(cellId);
          const matrix_id = Number(matrixId);
          const response = await axios.get(`http://localhost:3002/api/v1/customer-instance/state_action_matrix/cell/${cell_id}/matrix/${matrix_id}`);
          return response?.data ?? {};
      } catch (error) {
          this.catchError('State Action Matrix Configuration', 'getStateActionMatrices', error);
      }
  }

  async getStateActionMatrixVersion(cellId, matrixId, version) {
      try {
          const cell_id = Number(cellId);
          const matrix_id = Number(matrixId);
          const response = await axios.get(`http://localhost:3002/api/v1/customer-instance/state_action_matrix/cell/${cell_id}/matrix/${matrix_id}/history/${version}`);
          return response?.data ?? {};
      } catch (error) {
          this.catchError('State Action Matrix Configuration', 'getStateActionMatrices', error);
      }
  }

  async createStateActionMatrix(cellId, productId, requestBody) {
      try {
          const cell_id = Number(cellId);
          const product_id = Number(productId);
          const response = await axios.post(`http://localhost:3002/api/v1//state_action_matrix/cell/${cell_id}/product/${product_id}`, requestBody);
          return response?.status;
      } catch (error) {
          this.catchError('State Action Matrix Configuration', 'createStateActionMatrix', error);
      }
  }

  async updateStateActionMatrix(cellId, productId, requestBody) {
      try {
          const cell_id = Number(cellId);
          const product_id = Number(productId);
          const response = await axios.put(`http://localhost:3002/api/v1/customer-instance/state_action_matrix/cell/${cell_id}/product/${product_id}`, requestBody);
          return response?.status;
      } catch (error) {
          this.catchError('State Action Matrix Configuration', 'updateStateActionMatrix', error);
      }
  }

  async getRuns(requestData) {
    try {
      const {
        facility_id,
        cell_id,
        product_id,
        run_id,
        start,
        end,
        page_size,
        page_num,
        q,
        sort,
        sort_order,
      } = requestData;
      const queryParams = {
        cell_id,
        product_id,
        run_id,
        start,
        end,
        page_size,
        page_num,
        q,
        sort,
        sort_order,
      };

      const runList = await this.instance.get(`/runs/${facility_id}`, {
        params: queryParams,
      });

      if (runList?.status === 200) {
        return runList?.data ?? {};
      } else {
        Notification.FailedNotification(
          "Historical Runs",
          "Failed to fetch Run data"
        );
        return {};
      }
    } catch (ex) {
      this.catchError("Rules Configuration", "getRuns", ex);
    }
  }

  async getCurrentRun(facilityId, cellId) {
    try {
      const response = await this.instance.get(`/runs/current/cell/${cellId}`);

      // Alert user that the Cell is down if there is no current run returned-
      const responseLength = response.data.data.data.length;
      if (responseLength === 0) {
        Notification.InformationNotification(
          "Cell Down",
          "Current run not found."
        );
      }

      return response?.data;
    } catch (error) {
      console.error("Exception in getCurrentRun: ", error);
      Notification.FailedNotification("Runs", error?.message);
      return [];
    }
  }

  async getControllersByCell(cellId) {
    try {
      const response = await this.instance.get(
        `/rule-configs/controllers/cell/${cellId}`
      );
      return response?.data ?? [];
    } catch (error) {
      this.catchError("Rules Configuration", "getControllersByCell", error);
    }
  }

  async getMeasurementSensors(cellId, equipmentId) {
    try {
      const response = await this.instance.get(
        `/rule-configs/${cellId}/sensors/${equipmentId}`
      );

      return response?.data ?? [];
    } catch (error) {
      this.catchError("Rules Configuration", "getMeasurementSensors", error);
    }
  }

  async getMeasurementEquipment(cellId) {
    try {
      const response = await this.instance.get(
        `/rule-configs/outputs/cell/${cellId}/equipment`
      );
      return response?.data ?? [];
    } catch (error) {
      this.catchError("Equipment", "getMeasurementEquipment", error);
    }
  }

  async getCaliperDetails(cellId, equipmentDataTagId) {
    try {
      const cell_id = Number(cellId);
      const equipmentDataTag_id = Number(equipmentDataTagId);
      const response = await this.instance(
        `/rule-configs/cell/${cell_id}/equipmentdatatagid/${equipmentDataTag_id}`
      );
      return response?.data ?? {};
    } catch (error) {
      this.catchError("Rules Configuration", "getCaliperDetails", error);
    }
  }

  async getEquipmentTemplate(reqObj) {
    try {
      const cellId = reqObj?.cell?.id;

      const result = await this.instance.get(`/ui-templates/${cellId}`);

      if (result?.status === 200) {
        const equipmentData = result?.data?.data ?? {};
        return equipmentData;
      } else {
        Notification.FailedNotification(
          "Signal Data",
          "Failed to fetch Signal Data Template"
        );
        return {};
      }
    } catch (ex) {
      this.catchError("Signal Data", "getEquipmentTemplate", ex);
    }
  }

  async getControllableEquipByCell(cellId) {
    try {
      const cell_id = Number(cellId);
      const response = await this.instance.get(
        `/equipment/controllers/cell/${cell_id}`
      );
      return response?.data ?? {};
    } catch (error) {
      this.catchError(
        "Controllable Equipment",
        "getControllableEquipByCell",
        error
      );
    }
  }

  async getProductListByCell(cellId, pageNum) {
    try {
      const cell_id = Number(cellId);
      const newUrl = this.handlePagination(
        `/products/cell/${cell_id}`,
        pageNum
      );
      const response = await this.instance.get(newUrl);
      return response?.data ?? {};
    } catch (error) {
      this.catchError("Products", "getProductListByCell", error);
    }
  }

  async getProductsByFacility(facilityId, pageNum) {
    try {
      const facility_id = Number(facilityId);
      const newUrl = this.handlePagination(
        `/products/facility/${facility_id}`,
        pageNum
      );
      const response = await this.instance.get(newUrl);
      return response?.data ?? {};
    } catch (error) {
      this.catchError("Products", "getProductsByFacility", error);
    }
  }

  async getProducts(productNumber) {
    try {
      const response = await this.instance.get(
        `/parts/parts/product-number/${productNumber}`
      );
      return response?.data ?? {};
    } catch (error) {
      this.catchError("Products", "getProducts", error);
    }
  }

  async putProducts(partId, productRequestBody) {
    try {
      const id = Number(partId);
      const queryParams = { id };
      const url = `/parts/parts/part_id`;
      const response = await this.instance.put(url, productRequestBody, {
        params: queryParams,
      });
      return response?.status;
    } catch (error) {
      this.catchError("Products", "putProducts", error);
    }
  }

  async postProducts(productRequestBody) {
    try {
      const url = `/parts/parts`;
      const response = await this.instance.post(url, productRequestBody);
      return response?.status;
    } catch (error) {
      this.catchError("Products", "postProducts", error);
    }
  }

  async postProductFacility(productRequestBody) {
    try {
      const url = `/part-facility/part-facilities`;
      const response = await this.instance.post(url, productRequestBody);
      return response?.status;
    } catch (error) {
      this.catchError("Products", "postProductFacility", error);
    }
  }

  async getProductsByERPNumber(erpNumber) {
    try {
      const url = `/parts/parts/product-number/${erpNumber}`;
      const response = await this.instance.get(url);
      return response?.data ?? {};
    } catch (error) {
      this.catchError("Products", "getProductsByERPNumber", error);
    }
  }

  async getCellStatusbyFacilityId(facilityId) {
    try {
      const url = `/cell-status/cell_status/facility/${facilityId}`;
      const response = await this.instance.get(url);
      return response?.data ?? {};
    } catch (error) {
      this.catchError("Dashboard", "getCellStatusbyFacilityId", error);
    }
  }

  async getMappingByProductsFacility(part_id, facility_id) {
    try {
      const partId = Number(part_id);
      const facilityId = Number(facility_id);
      const url = `/part-facility/part-facilities/partId/${partId}/facilityId/${facilityId}`;
      const response = await this.instance.get(url);
      return response?.data ?? {};
    } catch (error) {
      this.catchError("Products", "getMappingByProductsFacility", error);
    }
  }

  async putProductionStatusProductsFacility(
    partFacilityId,
    productRequestBody
  ) {
    try {
      const id = Number(partFacilityId);
      const queryParams = { id };
      const url = `/part-facility/part-facilities/part_facility_id`;
      const response = await this.instance.put(url, productRequestBody, {
        params: queryParams,
      });
      return response?.status;
    } catch (error) {
      this.catchError("Products", "putProductionStatusProductsFacility", error);
    }
  }

  async putFacilityLineAssignedParts(cellId, facilityLineRequestBody) {
    try {
      const cell_id = Number(cellId);
      const url = `/products-assigned/assigned-parts/cell/${cell_id}/product`;
      const response = await this.instance.put(url, facilityLineRequestBody);
      return response?.status;
    } catch (error) {
      this.catchError("Products", "putFacilityLineAssignedParts", error);
    }
  }

  async putPartAttributeValuesFacilityLine(
    partAttributeValuesFacilityLineRequestBody
  ) {
    try {
      const url = `/part-attributes/part-attributes`;
      const response = await this.instance.put(
        url,
        partAttributeValuesFacilityLineRequestBody
      );
      return response?.status;
    } catch (error) {
      this.catchError("Products", "putPartAttributeValuesFacilityLine", error);
    }
  }

  async postPartAttributeValuesFacilityLine(
    partAttributeValuesFacilityLineRequestBody
  ) {
    try {
      const url = `/part-attributes/part-attributes`;
      const response = await this.instance.post(
        url,
        partAttributeValuesFacilityLineRequestBody
      );
      return response?.status;
    } catch (error) {
      this.catchError("Products", "postPartAttributeValuesFacilityLine", error);
    }
  }

  async postFacilityLineAssignedParts(
    cell_id,
    part_id,
    masterFacilityLineAssignedPartsRequestBody
  ) {
    try {
      const cellId = Number(cell_id);
      const productId = Number(part_id);
      const url = `/products-assigned/assigned-parts/cell/${cellId}/product/${productId}`;
      const response = await this.instance.post(
        url,
        masterFacilityLineAssignedPartsRequestBody
      );
      return response?.status;
    } catch (error) {
      this.catchError("Products", "postFacilityLineAssignedParts", error);
    }
  }

  async getProductionStatus() {
    try {
      const response = await this.instance.get(
        `/products-status/enum/production-status`
      );
      return response?.data ?? {};
    } catch (error) {
      this.catchError("Production Status", "getProductionStatus", error);
    }
  }

  async getOPCDevices() {
    try {
      const response = await this.instance.get(`/equipment/opc/devices`);
      return response?.data ?? {};
    } catch (error) {
      this.catchError("OPC Devices", "getOPCDevices", error);
    }
  }

  async getOPCConnections(cellId) {
    try {
      const cell_id = Number(cellId);
      const response = await this.instance.get(
        `/customer-instance/opc-connections`,
        { params: { cell_id: cell_id } }
      );
      return response?.data ?? {};
    } catch (error) {
      this.catchError("OPC Connections", "getOPCConnections", error);
    }
  }

  async getInputSignalsData(cellId, productId, reqBody) {
    try {
      const cell_id = Number(cellId);
      const product_id = Number(productId);
      const { page_size, page_num, sort, sort_order } = reqBody;
      const queryParams = { page_size, page_num, sort, sort_order };

      const response = await this.instance.get(
        `/data_science/physics_controllable_input_signals/cell/${cell_id}/product/${product_id}`,
        { params: queryParams }
      );
      return response?.data ?? {};
    } catch (error) {
      this.catchError("ML Configuration", "getInputSignalsData", error);
    }
  }

  async getDashboardUrlList(cell_id, service_type_id) {
    try {
      const queryParams = {
        cell_id: Number(cell_id),
        service_type_id: service_type_id,
        current_run: true,
      };
      let response = await this.instance.get(`/dashboards`, {
        params: queryParams,
      });

      // Alert user if no dashboards are found / returned-
      const responseLength = response.data.length;
      if (responseLength === 0) {
        Notification.InformationNotification(
          "Dashboards",
          "No dashboards found."
        );
      }

      return response?.data ?? [];
    } catch (error) {
      this.catchError("Dashboard", "getDashboardUrlList", error);
    }
  }

  async getOutputSignalsData(cellId, productId, reqBody) {
    try {
      const cell_id = Number(cellId);
      const product_id = Number(productId);
      const { page_size, page_num, sort, sort_order } = reqBody;
      const queryParams = { page_size, page_num, sort, sort_order };

      const response = await this.instance.get(
        `/data_science/physics_output_signals/cell/${cell_id}/product/${product_id}`,
        { params: queryParams }
      );
      return response?.data ?? {};
    } catch (error) {
      this.catchError("ML Configuration", "getOutputSignalsData", error);
    }
  }

  async getDashboardsByRunId(reqObj) {
    try {
      const { run_id, cell_id } = reqObj;
      const response = await this.instance.get(
        `/dashboards/cellid/${cell_id}/runid/${run_id}`
      );
      return response?.data ?? {};
    } catch (error) {
      this.catchError("Dashboard", "getDashboardsByRunId", error);
    }
  }

  async getRunReportPDFUrl(cell_id, run_id) {
    try {
      const response = await this.instance.get(
        `/reporting/cell/${cell_id}/run/${run_id}/summary/pdf`
      );
      return response ?? {};
    } catch (error) {
      const errorMessage = {message: error.response.data.detail}
      this.catchError("Historical Runs", "getRunReportPDFUrl", errorMessage);
    }
  }

}
