import React, { lazy } from "react";

const MlConfiguration = lazy(() => import('./ml-configuration'))
const RulesConfiguration = lazy(() => import('./rules-configuration'))
const StateConfiguration = lazy(() => import('./state-configuration'))

const ControlsRoute = () => {
    return [
    {
        key: 'rules_configuration',
        path: '/controls_admin/rules_configuration',
        component: <RulesConfiguration />
    },
    {
        key: 'ml_configuration',
        path: '/controls_admin/ml_configuration',
        component: <MlConfiguration />
    },
    {
        key: 'state_action_matrix',
        path: '/controls_admin/state_action_matrix',
        component: <StateConfiguration />
    }]
}

export default ControlsRoute;